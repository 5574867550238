const Ankerslogen = ({ isMobile, ...props }: { isMobile: boolean }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 2390 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M568.809 88.3309C568.205 88.3309 567.802 87.9282 567.802 87.3241V30.2718C567.802 29.8691 567.601 29.6677 567.198 29.6677H549.007C548.403 29.6677 548 29.265 548 28.6609V19.8681C548 19.264 548.403 18.8613 549.007 18.8613H598.747C599.351 18.8613 599.754 19.264 599.754 19.8681V28.6609C599.754 29.265 599.351 29.6677 598.747 29.6677H580.556C580.153 29.6677 579.952 29.8691 579.952 30.2718V87.3241C579.952 87.9282 579.549 88.3309 578.945 88.3309H568.943H568.809Z"
        fill="#00BEFA"
      />
      <path
        d="M640.574 88.3288C639.97 88.3288 639.567 87.926 639.567 87.3219V58.8629C639.567 52.5536 636.412 48.1236 630.237 48.1236C624.061 48.1236 620.839 52.5536 620.839 58.8629V87.3219C620.839 87.926 620.436 88.3288 619.832 88.3288H610.435C609.83 88.3288 609.428 87.926 609.428 87.3219V19.7988C609.428 19.1947 609.83 18.792 610.435 18.792H619.832C620.436 18.792 620.839 19.1947 620.839 19.7988V43.6266H620.973C623.121 40.539 627.417 37.9213 633.996 37.9213C644.535 37.9213 651.046 45.7073 651.046 56.2452V87.3219C651.046 87.926 650.643 88.3288 650.039 88.3288H640.641H640.574Z"
        fill="#00BEFA"
      />
      <path
        d="M663.722 75.7797C662.715 72.6251 661.977 68.7992 661.977 63.6981C661.977 58.5969 662.581 54.7039 663.588 51.5493C666.34 42.9579 673.925 37.9238 683.725 37.9238C693.526 37.9238 701.245 43.1592 703.997 51.5493C705.138 55.0395 705.608 58.3955 705.608 66.3829C705.608 66.987 705.205 67.3897 704.467 67.3897H674.059C673.656 67.3897 673.455 67.5911 673.455 67.9938C673.455 69.4033 673.791 70.6786 674.193 71.7525C675.804 76.5852 679.698 79.2029 685.269 79.2029C690.841 79.2029 694.398 77.1893 696.815 74.5044C697.352 73.9675 697.822 73.9004 698.359 74.3031L704.4 79.6056C704.937 80.0083 704.937 80.5453 704.534 81.0151C700.373 85.9149 693.391 89.4052 684.262 89.4052C673.858 89.4052 666.474 84.304 663.722 75.7126V75.7797ZM693.526 53.9656C692.183 50.0055 688.491 47.6563 683.927 47.6563C679.362 47.6563 675.536 50.0055 674.193 53.9656C673.791 55.1738 673.589 56.4491 673.589 58.1271C673.589 58.5298 673.791 58.7311 674.193 58.7311H693.526C693.928 58.7311 694.13 58.5298 694.13 58.1271C694.13 56.3819 693.928 55.1738 693.526 53.9656Z"
        fill="#00BEFA"
      />
      <path
        d="M791.607 88.3288C791.003 88.3288 790.6 87.926 790.399 87.3219L777.108 39.7336H776.907L763.481 87.3219C763.28 87.926 762.877 88.3288 762.273 88.3288H754.621C754.017 88.3288 753.614 87.926 753.412 87.3219L736.295 19.7988C736.161 19.1947 736.43 18.792 737.101 18.792H746.7C747.438 18.792 747.841 19.1947 747.908 19.7988L758.984 66.6489H759.185L772.208 19.7988C772.409 19.1947 772.812 18.792 773.416 18.792H780.598C781.337 18.792 781.605 19.1947 781.807 19.7988L795.098 66.6489H795.299L806.039 19.7988C806.173 19.0605 806.643 18.792 807.247 18.792H816.779C817.518 18.792 817.719 19.1947 817.585 19.7988L800.602 87.3219C800.468 87.926 799.998 88.3288 799.394 88.3288H791.607Z"
        fill="#00BEFA"
      />
      <path
        d="M822.816 75.9162C821.675 72.426 821.205 69.137 821.205 63.7674C821.205 58.3978 821.742 55.0417 822.816 51.6186C825.568 43.0272 833.153 37.9932 843.155 37.9932C853.157 37.9932 860.675 43.0272 863.427 51.6186C864.568 55.1089 865.038 58.3978 865.038 63.7674C865.038 69.137 864.501 72.4931 863.427 75.9162C860.675 84.5076 853.09 89.5417 843.155 89.5417C833.221 89.5417 825.568 84.5076 822.816 75.9162ZM852.351 72.8287C853.157 70.2781 853.358 67.9289 853.358 63.7003C853.358 59.4717 853.157 57.2567 852.351 54.5719C850.942 50.4776 847.72 48.1283 843.155 48.1283C838.591 48.1283 835.301 50.4776 833.825 54.5719C833.019 57.2567 832.818 59.4717 832.818 63.7003C832.818 67.9289 833.019 70.2781 833.825 72.8287C835.234 76.923 838.523 79.2722 843.155 79.2722C847.787 79.2722 850.942 76.923 852.351 72.8287Z"
        fill="#00BEFA"
      />
      <path
        d="M877.593 88.3313C876.989 88.3313 876.586 87.9285 876.586 87.3244V40.0717C876.586 39.4676 876.989 39.0649 877.593 39.0649H886.99C887.594 39.0649 887.997 39.4676 887.997 40.0717V44.2331H888.132C890.481 40.4744 894.71 37.9238 900.885 37.9238C904.779 37.9238 908.672 39.4676 911.223 42.0182C911.76 42.5551 911.827 42.9579 911.424 43.4277L905.987 50.274C905.584 50.811 905.047 50.8781 904.577 50.4753C902.295 49.0658 899.946 48.1261 897.328 48.1261C890.884 48.1261 887.997 52.7574 887.997 60.6105V87.3244C887.997 87.9285 887.594 88.3313 886.99 88.3313H877.593Z"
        fill="#00BEFA"
      />
      <path
        d="M933.576 89.0022C923.977 89.0022 920.084 84.3709 920.084 74.9069V19.8681C920.084 19.264 920.487 18.8613 921.091 18.8613H930.489C931.093 18.8613 931.495 19.264 931.495 19.8681V74.3028C931.495 77.6588 932.838 78.7999 935.791 78.7999H938.074C938.678 78.7999 939.081 79.2026 939.081 79.8067V87.9954C939.081 88.5995 938.678 89.0022 938.074 89.0022H933.576Z"
        fill="#00BEFA"
      />
      <path
        d="M979.755 88.3288C979.151 88.3288 978.748 87.926 978.748 87.3219V83.6303H978.614C976.265 86.9863 972.17 89.4698 965.525 89.4698C957.134 89.4698 951.025 85.5097 948.407 77.5895C947.199 73.8308 946.662 70.3405 946.662 63.6956C946.662 57.0506 947.199 53.5604 948.407 49.8016C950.958 41.9485 957.134 37.9213 965.525 37.9213C972.17 37.9213 976.265 40.4048 978.614 43.7608H978.748V19.7988C978.748 19.1947 979.151 18.792 979.755 18.792H989.153C989.757 18.792 990.16 19.1947 990.16 19.7988V87.3219C990.16 87.926 989.757 88.3288 989.153 88.3288H979.755ZM977.674 72.8911C978.413 70.609 978.681 68.1926 978.681 63.6956C978.681 59.1985 978.346 56.715 977.674 54.5001C976.332 50.4057 973.177 48.1907 968.478 48.1907C963.779 48.1907 960.692 50.4728 959.349 54.5001C958.611 56.7821 958.342 59.1985 958.342 63.6956C958.342 68.1926 958.678 70.6761 959.349 72.8911C960.692 76.9854 963.846 79.2004 968.478 79.2004C973.11 79.2004 976.332 76.9183 977.674 72.8911Z"
        fill="#00BEFA"
      />
      <path
        d="M1002.17 37.655V19.8681C1002.17 19.264 1002.57 18.8613 1003.18 18.8613H1011.43C1012.04 18.8613 1012.44 19.264 1012.44 19.8681V29.4663C1012.44 30.4731 1012.24 31.0101 1011.64 31.6142L1004.65 37.9235C1004.12 38.4605 1003.65 38.6618 1003.11 38.6618C1002.51 38.6618 1002.1 38.2591 1002.1 37.655H1002.17Z"
        fill="#00BEFA"
      />
      <path
        d="M1016.67 81.6863C1016.27 81.2836 1016.27 80.6795 1016.67 80.2768L1022.51 74.1688C1022.91 73.7661 1023.52 73.7661 1023.92 74.1688C1027.48 77.4577 1033.25 79.807 1038.35 79.807C1044.39 79.807 1047.48 77.3235 1047.48 73.9675C1047.48 71.0142 1045.74 69.1348 1039.29 68.5307L1033.99 67.9938C1023.99 66.987 1018.82 61.8858 1018.82 53.4958C1018.82 43.9647 1025.67 37.9238 1037.95 37.9238C1045.6 37.9238 1052.05 40.4744 1056.48 43.9647C1057.01 44.3674 1057.01 44.9715 1056.68 45.3742L1051.64 51.415C1051.24 51.952 1050.7 51.952 1050.23 51.6164C1047.15 49.4685 1042.25 47.522 1037.35 47.522C1032.45 47.522 1029.89 49.6699 1029.89 52.6232C1029.89 55.5765 1031.64 57.2545 1037.95 57.8586L1043.25 58.3956C1053.59 59.4024 1058.49 64.7049 1058.49 72.8264C1058.49 82.626 1051.31 89.4723 1037.82 89.4723C1027.68 89.4723 1020.7 85.5122 1016.67 81.6863Z"
        fill="#00BEFA"
      />
      <path
        d="M1366.67 19.8681C1366.67 19.264 1367.08 18.8613 1367.68 18.8613H1376.81C1377.75 18.8613 1378.22 19.1969 1378.56 19.8681L1397.69 62.691H1398.09L1416.88 19.8681C1417.22 19.1298 1417.69 18.8613 1418.63 18.8613H1427.62C1428.23 18.8613 1428.63 19.264 1428.63 19.8681V87.3913C1428.63 87.9954 1428.23 88.3981 1427.62 88.3981H1418.7C1418.09 88.3981 1417.69 87.9954 1417.69 87.3913V44.8369H1417.29L1402.59 77.5917C1402.18 78.5314 1401.58 78.9341 1400.64 78.9341H1394.73C1393.79 78.9341 1393.19 78.5314 1392.79 77.5917L1378.09 44.8369H1377.68V87.3913C1377.68 87.9954 1377.28 88.3981 1376.68 88.3981H1367.75C1367.14 88.3981 1366.74 87.9954 1366.74 87.3913V19.8681H1366.67Z"
        fill="#00BEFA"
      />
      <path
        d="M1442.31 75.9162C1441.17 72.426 1440.7 69.137 1440.7 63.7674C1440.7 58.3978 1441.24 55.0417 1442.31 51.6186C1445.07 43.0272 1452.65 37.9932 1462.65 37.9932C1472.65 37.9932 1480.17 43.0272 1482.93 51.6186C1484.07 55.1089 1484.54 58.3978 1484.54 63.7674C1484.54 69.137 1484 72.4931 1482.93 75.9162C1480.17 84.5076 1472.59 89.5417 1462.65 89.5417C1452.72 89.5417 1445.07 84.5076 1442.31 75.9162ZM1471.92 72.8287C1472.72 70.2781 1472.92 67.9289 1472.92 63.7003C1472.92 59.4717 1472.72 57.2567 1471.92 54.5719C1470.51 50.4776 1467.28 48.1283 1462.72 48.1283C1458.16 48.1283 1454.87 50.4776 1453.39 54.5719C1452.58 57.2567 1452.38 59.4717 1452.38 63.7003C1452.38 67.9289 1452.58 70.2781 1453.39 72.8287C1454.8 76.923 1458.09 79.2722 1462.72 79.2722C1467.35 79.2722 1470.51 76.923 1471.92 72.8287Z"
        fill="#00BEFA"
      />
      <path
        d="M1507.7 83.6303H1507.56V87.3219C1507.56 87.926 1507.16 88.3288 1506.56 88.3288H1497.16C1496.56 88.3288 1496.15 87.926 1496.15 87.3219V19.7988C1496.15 19.1947 1496.56 18.792 1497.16 18.792H1506.56C1507.16 18.792 1507.56 19.1947 1507.56 19.7988V43.7608H1507.7C1510.05 40.4048 1514.14 37.9213 1520.79 37.9213C1529.18 37.9213 1535.29 41.8814 1537.9 49.8016C1539.11 53.5604 1539.65 57.0506 1539.65 63.6956C1539.65 70.3405 1539.11 73.8308 1537.9 77.5895C1535.35 85.4426 1529.18 89.4698 1520.79 89.4698C1514.14 89.4698 1510.05 86.9863 1507.7 83.6303ZM1526.9 72.8911C1527.63 70.609 1527.9 68.1926 1527.9 63.6956C1527.9 59.1985 1527.57 56.715 1526.9 54.5001C1525.55 50.4057 1522.4 48.1907 1517.77 48.1907C1513.14 48.1907 1509.91 50.4728 1508.57 54.5001C1507.83 56.7821 1507.56 59.1985 1507.56 63.6956C1507.56 68.1926 1507.9 70.6761 1508.57 72.8911C1509.91 76.9854 1513.07 79.2004 1517.77 79.2004C1522.47 79.2004 1525.55 76.9183 1526.9 72.8911Z"
        fill="#00BEFA"
      />
      <path
        d="M1552.41 29.7326C1551.8 29.7326 1551.4 29.3299 1551.4 28.7258V19.7988C1551.4 19.1947 1551.8 18.792 1552.41 18.792H1561.8C1562.41 18.792 1562.81 19.1947 1562.81 19.7988V28.7258C1562.81 29.3299 1562.41 29.7326 1561.8 29.7326H1552.41ZM1552.41 88.3288C1551.8 88.3288 1551.4 87.926 1551.4 87.3219V40.0692C1551.4 39.4651 1551.8 39.0624 1552.41 39.0624H1561.8C1562.41 39.0624 1562.81 39.4651 1562.81 40.0692V87.3219C1562.81 87.926 1562.41 88.3288 1561.8 88.3288H1552.41Z"
        fill="#00BEFA"
      />
      <path
        d="M1589.79 89.0022C1580.19 89.0022 1576.3 84.3709 1576.3 74.9069V19.8681C1576.3 19.264 1576.7 18.8613 1577.31 18.8613H1586.7C1587.31 18.8613 1587.71 19.264 1587.71 19.8681V74.3028C1587.71 77.6588 1589.05 78.7999 1592.01 78.7999H1594.29C1594.89 78.7999 1595.3 79.2026 1595.3 79.8067V87.9954C1595.3 88.5995 1594.89 89.0022 1594.29 89.0022H1589.79Z"
        fill="#00BEFA"
      />
      <path
        d="M1604.36 75.7797C1603.35 72.6251 1602.61 68.7992 1602.61 63.6981C1602.61 58.5969 1603.22 54.7039 1604.22 51.5493C1606.98 42.9579 1614.56 37.9238 1624.36 37.9238C1634.16 37.9238 1641.88 43.1592 1644.63 51.5493C1645.78 55.0395 1646.25 58.3955 1646.25 66.3829C1646.25 66.987 1645.84 67.3897 1645.1 67.3897H1614.7C1614.29 67.3897 1614.09 67.5911 1614.09 67.9938C1614.09 69.4033 1614.43 70.6786 1614.83 71.7525C1616.44 76.5852 1620.33 79.2029 1625.91 79.2029C1631.48 79.2029 1635.04 77.1893 1637.45 74.5044C1637.99 73.9675 1638.46 73.9004 1639 74.3031L1645.04 79.6056C1645.57 80.0083 1645.57 80.5453 1645.17 81.0151C1641.01 85.9149 1634.03 89.4052 1624.9 89.4052C1614.49 89.4052 1607.11 84.304 1604.36 75.7126V75.7797ZM1634.1 53.9656C1632.75 50.0055 1629.06 47.6563 1624.5 47.6563C1619.93 47.6563 1616.11 50.0055 1614.76 53.9656C1614.36 55.1738 1614.16 56.4491 1614.16 58.1271C1614.16 58.5298 1614.36 58.7311 1614.76 58.7311H1634.1C1634.5 58.7311 1634.7 58.5298 1634.7 58.1271C1634.7 56.3819 1634.5 55.1738 1634.1 53.9656Z"
        fill="#00BEFA"
      />
      <path
        d="M1680.89 53.5632C1680.89 42.4884 1681.29 39.0652 1682.7 34.7695C1686.26 23.4262 1695.05 17.6538 1706.87 17.6538C1718.01 17.6538 1726.4 23.359 1730.36 32.5545C1730.56 33.0915 1730.36 33.5613 1729.82 33.7627L1721.1 37.9242C1720.49 38.2598 1719.96 37.9242 1719.75 37.3872C1717.47 32.4874 1713.65 28.3931 1706.73 28.3931C1699.82 28.3931 1696.19 31.9504 1694.25 37.5886C1693.31 40.4747 1693.04 43.2267 1693.04 53.429C1693.04 63.6313 1693.37 66.4504 1694.25 69.2694C1696.19 74.9075 1700.09 78.4649 1706.73 78.4649C1713.38 78.4649 1717.47 74.3706 1719.75 69.4708C1719.96 68.9338 1720.49 68.6653 1721.1 68.9338L1729.82 73.0953C1730.36 73.2966 1730.56 73.8336 1730.36 74.3034C1726.4 83.4989 1718.01 89.2042 1706.87 89.2042C1695.12 89.2042 1686.33 83.4989 1682.7 72.1556C1681.29 67.8599 1680.89 64.3696 1680.89 53.3619V53.5632Z"
        fill="#00BEFA"
      />
      <path
        d="M1771.97 88.3288C1771.37 88.3288 1770.96 87.926 1770.96 87.3219V58.8629C1770.96 52.5536 1767.81 48.1236 1761.63 48.1236C1755.46 48.1236 1752.24 52.5536 1752.24 58.8629V87.3219C1752.24 87.926 1751.83 88.3288 1751.23 88.3288H1741.83C1741.23 88.3288 1740.82 87.926 1740.82 87.3219V19.7988C1740.82 19.1947 1741.23 18.792 1741.83 18.792H1751.23C1751.83 18.792 1752.24 19.1947 1752.24 19.7988V43.6266H1752.37C1754.52 40.539 1758.81 37.9213 1765.39 37.9213C1775.93 37.9213 1782.44 45.7073 1782.44 56.2452V87.3219C1782.44 87.926 1782.04 88.3288 1781.44 88.3288H1772.04H1771.97Z"
        fill="#00BEFA"
      />
      <path
        d="M1824.73 88.3286C1824.13 88.3286 1823.73 87.9258 1823.73 87.3217V83.7644H1823.59C1821.24 87.1204 1816.61 89.4696 1809.7 89.4696C1800.7 89.4696 1793.12 84.7712 1793.12 74.4346C1793.12 64.0981 1800.7 58.7956 1812.85 58.7956H1822.85C1823.26 58.7956 1823.46 58.5942 1823.46 58.1915V55.8423C1823.46 50.2042 1820.77 47.6536 1812.52 47.6536C1807.01 47.6536 1803.59 49.1973 1801.24 51.0096C1800.7 51.4123 1800.1 51.3452 1799.83 50.8082L1796.07 44.0291C1795.74 43.4921 1795.87 43.0223 1796.27 42.6867C1800.23 39.8005 1805.67 37.854 1813.79 37.854C1828.83 37.854 1834.33 42.9552 1834.33 55.0368V87.2546C1834.33 87.8587 1833.93 88.2614 1833.33 88.2614H1824.73V88.3286ZM1823.46 71.8169V68.0582C1823.46 67.6555 1823.26 67.4541 1822.85 67.4541H1814.66C1807.28 67.4541 1803.99 69.4677 1803.99 74.099C1803.99 78.1934 1806.94 80.207 1812.52 80.207C1819.56 80.207 1823.46 77.4551 1823.46 71.8169Z"
        fill="#00BEFA"
      />
      <path
        d="M1848.84 88.3313C1848.23 88.3313 1847.83 87.9285 1847.83 87.3244V40.0717C1847.83 39.4676 1848.23 39.0649 1848.84 39.0649H1858.23C1858.84 39.0649 1859.24 39.4676 1859.24 40.0717V44.2331H1859.37C1861.72 40.4744 1865.95 37.9238 1872.13 37.9238C1876.02 37.9238 1879.91 39.4676 1882.47 42.0182C1883 42.5551 1883.07 42.9579 1882.67 43.4277L1877.23 50.274C1876.83 50.811 1876.29 50.8781 1875.82 50.4753C1873.54 49.0658 1871.19 48.1261 1868.57 48.1261C1862.13 48.1261 1859.24 52.7574 1859.24 60.6105V87.3244C1859.24 87.9285 1858.84 88.3313 1858.23 88.3313H1848.84Z"
        fill="#00BEFA"
      />
      <path
        d="M1889.78 100.549C1889.38 100.012 1889.38 99.6097 1889.91 99.1399L1895.95 93.099C1896.49 92.5621 1896.96 92.5621 1897.5 93.099C1900.59 96.0523 1904.14 97.7304 1908.24 97.7304C1915.82 97.7304 1919.31 93.636 1919.31 85.9843V81.353H1919.18C1916.9 84.8432 1912.74 87.0582 1906.43 87.0582C1898.17 87.0582 1892.2 82.8967 1889.64 75.1108C1888.44 71.352 1888.03 67.9289 1888.03 62.5592C1888.03 57.1896 1888.44 53.8336 1889.64 50.0748C1892.2 42.2217 1898.1 37.9932 1906.43 37.9932C1912.74 37.9932 1916.96 40.2753 1919.18 43.8326H1919.31V40.141C1919.31 39.5369 1919.72 39.1342 1920.32 39.1342H1929.72C1930.32 39.1342 1930.73 39.5369 1930.73 40.141V85.3802C1930.73 99.4755 1922.87 108 1907.7 108C1900.85 108 1893.6 105.114 1889.78 100.617V100.549ZM1918.11 70.5466C1918.91 68.2645 1919.25 65.8482 1919.25 62.4921C1919.25 59.1361 1918.91 56.7869 1918.11 54.4377C1916.76 50.4776 1914.01 48.1955 1909.31 48.1955C1904.61 48.1955 1901.93 50.4776 1900.59 54.4377C1899.78 56.7869 1899.44 59.2704 1899.44 62.4921C1899.44 65.7139 1899.78 68.3316 1900.59 70.5466C1901.93 74.6409 1904.68 76.8559 1909.31 76.8559C1913.94 76.8559 1916.76 74.5738 1918.11 70.5466Z"
        fill="#00BEFA"
      />
      <path
        d="M1945.43 29.7326C1944.83 29.7326 1944.43 29.3299 1944.43 28.7258V19.7988C1944.43 19.1947 1944.83 18.792 1945.43 18.792H1954.83C1955.43 18.792 1955.84 19.1947 1955.84 19.7988V28.7258C1955.84 29.3299 1955.43 29.7326 1954.83 29.7326H1945.43ZM1945.43 88.3288C1944.83 88.3288 1944.43 87.926 1944.43 87.3219V40.0692C1944.43 39.4651 1944.83 39.0624 1945.43 39.0624H1954.83C1955.43 39.0624 1955.84 39.4651 1955.84 40.0692V87.3219C1955.84 87.926 1955.43 88.3288 1954.83 88.3288H1945.43Z"
        fill="#00BEFA"
      />
      <path
        d="M2000.67 88.3313C2000.07 88.3313 1999.67 87.9285 1999.67 87.3244V58.8654C1999.67 52.5561 1996.51 48.1261 1990.34 48.1261C1984.16 48.1261 1980.94 52.5561 1980.94 58.8654V87.3244C1980.94 87.9285 1980.54 88.3313 1979.93 88.3313H1970.53C1969.93 88.3313 1969.53 87.9285 1969.53 87.3244V40.0717C1969.53 39.4676 1969.93 39.0649 1970.53 39.0649H1979.93C1980.54 39.0649 1980.94 39.4676 1980.94 40.0717V43.6291H1981.07C1983.22 40.5415 1987.52 37.9238 1994.1 37.9238C2004.63 37.9238 2011.15 45.7098 2011.15 56.2477V87.3244C2011.15 87.9285 2010.74 88.3313 2010.14 88.3313H2000.74H2000.67Z"
        fill="#00BEFA"
      />
      <path
        d="M2024.03 100.549C2023.63 100.012 2023.63 99.6097 2024.16 99.1399L2030.2 93.099C2030.74 92.5621 2031.21 92.5621 2031.75 93.099C2034.84 96.0523 2038.39 97.7304 2042.49 97.7304C2050.07 97.7304 2053.56 93.636 2053.56 85.9843V81.353H2053.43C2051.15 84.8432 2046.99 87.0582 2040.68 87.0582C2032.42 87.0582 2026.45 82.8967 2023.89 75.1108C2022.69 71.352 2022.28 67.9289 2022.28 62.5592C2022.28 57.1896 2022.69 53.8336 2023.89 50.0748C2026.45 42.2217 2032.35 37.9932 2040.68 37.9932C2046.99 37.9932 2051.21 40.2753 2053.43 43.8326H2053.56V40.141C2053.56 39.5369 2053.97 39.1342 2054.57 39.1342H2063.97C2064.57 39.1342 2064.98 39.5369 2064.98 40.141V85.3802C2064.98 99.4755 2057.12 108 2041.95 108C2035.1 108 2027.85 105.114 2024.03 100.617V100.549ZM2052.36 70.5466C2053.16 68.2645 2053.5 65.8482 2053.5 62.4921C2053.5 59.1361 2053.16 56.7869 2052.36 54.4377C2051.01 50.4776 2048.26 48.1955 2043.56 48.1955C2038.86 48.1955 2036.18 50.4776 2034.84 54.4377C2034.03 56.7869 2033.69 59.2704 2033.69 62.4921C2033.69 65.7139 2034.03 68.3316 2034.84 70.5466C2036.18 74.6409 2038.93 76.8559 2043.56 76.8559C2048.19 76.8559 2051.01 74.5738 2052.36 70.5466Z"
        fill="#00BEFA"
      />
      <path
        d="M2104.18 19.8681C2104.18 19.264 2104.58 18.8613 2105.18 18.8613H2131.09C2145.79 18.8613 2152.77 26.2446 2152.77 37.3866C2152.77 45.2396 2149.08 49.7367 2144.72 52.0859V52.2873C2149.02 53.8982 2154.05 59.4692 2154.05 67.9263C2154.05 81.7531 2145.32 88.3981 2130.09 88.3981H2105.25C2104.65 88.3981 2104.24 87.9954 2104.24 87.3913V19.8681H2104.18ZM2130.82 47.7902C2136.93 47.7902 2140.76 44.0986 2140.76 38.6618C2140.76 33.2251 2137 29.4663 2130.82 29.4663H2116.93C2116.53 29.4663 2116.33 29.6677 2116.33 30.0704V47.119C2116.33 47.5217 2116.53 47.7231 2116.93 47.7231H2130.82V47.7902ZM2116.86 77.7259H2131.36C2138.14 77.7259 2141.9 73.833 2141.9 67.9935C2141.9 62.154 2138.14 58.3953 2131.36 58.3953H2116.86C2116.46 58.3953 2116.26 58.5966 2116.26 58.9993V77.1219C2116.26 77.5246 2116.46 77.7259 2116.86 77.7259Z"
        fill="#00BEFA"
      />
      <path
        d="M2166.74 88.3313C2166.14 88.3313 2165.73 87.9285 2165.73 87.3244V40.0717C2165.73 39.4676 2166.14 39.0649 2166.74 39.0649H2176.14C2176.74 39.0649 2177.15 39.4676 2177.15 40.0717V44.2331H2177.28C2179.63 40.4744 2183.86 37.9238 2190.03 37.9238C2193.93 37.9238 2197.82 39.4676 2200.37 42.0182C2200.91 42.5551 2200.98 42.9579 2200.57 43.4277L2195.14 50.274C2194.73 50.811 2194.2 50.8781 2193.73 50.4753C2191.44 49.0658 2189.09 48.1261 2186.48 48.1261C2180.03 48.1261 2177.15 52.7574 2177.15 60.6105V87.3244C2177.15 87.9285 2176.74 88.3313 2176.14 88.3313H2166.74Z"
        fill="#00BEFA"
      />
      <path
        d="M2238.43 88.3286C2237.83 88.3286 2237.42 87.9258 2237.42 87.3217V83.7644H2237.29C2234.94 87.1204 2230.31 89.4696 2223.39 89.4696C2214.4 89.4696 2206.81 84.7712 2206.81 74.4346C2206.81 64.0981 2214.4 58.7956 2226.55 58.7956H2236.55C2236.95 58.7956 2237.16 58.5942 2237.16 58.1915V55.8423C2237.16 50.2042 2234.47 47.6536 2226.21 47.6536C2220.71 47.6536 2217.29 49.1973 2214.94 51.0096C2214.4 51.4123 2213.8 51.3452 2213.53 50.8082L2209.77 44.0291C2209.43 43.4921 2209.57 43.0223 2209.97 42.6867C2213.93 39.8005 2219.37 37.854 2227.49 37.854C2242.53 37.854 2248.03 42.9552 2248.03 55.0368V87.2546C2248.03 87.8587 2247.63 88.2614 2247.02 88.2614H2238.43V88.3286ZM2237.16 71.8169V68.0582C2237.16 67.6555 2236.95 67.4541 2236.55 67.4541H2228.36C2220.98 67.4541 2217.69 69.4677 2217.69 74.099C2217.69 78.1934 2220.64 80.207 2226.21 80.207C2233.26 80.207 2237.16 77.4551 2237.16 71.8169Z"
        fill="#00BEFA"
      />
      <path
        d="M2292.66 88.3313C2292.06 88.3313 2291.65 87.9285 2291.65 87.3244V58.8654C2291.65 52.5561 2288.5 48.1261 2282.32 48.1261C2276.15 48.1261 2272.93 52.5561 2272.93 58.8654V87.3244C2272.93 87.9285 2272.52 88.3313 2271.92 88.3313H2262.52C2261.92 88.3313 2261.51 87.9285 2261.51 87.3244V40.0717C2261.51 39.4676 2261.92 39.0649 2262.52 39.0649H2271.92C2272.52 39.0649 2272.93 39.4676 2272.93 40.0717V43.6291H2273.06C2275.21 40.5415 2279.5 37.9238 2286.08 37.9238C2296.62 37.9238 2303.2 45.7098 2303.2 56.2477V87.3244C2303.2 87.9285 2302.8 88.3313 2302.19 88.3313H2292.79H2292.66Z"
        fill="#00BEFA"
      />
      <path
        d="M2347.45 88.3288C2346.84 88.3288 2346.44 87.926 2346.44 87.3219V83.6303H2346.31C2343.96 86.9863 2339.86 89.4698 2333.22 89.4698C2324.83 89.4698 2318.72 85.5097 2316.1 77.5895C2314.89 73.8308 2314.35 70.3405 2314.35 63.6956C2314.35 57.0506 2314.89 53.5604 2316.1 49.8016C2318.65 41.9485 2324.83 37.9213 2333.22 37.9213C2339.86 37.9213 2343.96 40.4048 2346.31 43.7608H2346.44V19.7988C2346.44 19.1947 2346.84 18.792 2347.45 18.792H2356.84C2357.45 18.792 2357.85 19.1947 2357.85 19.7988V87.3219C2357.85 87.926 2357.45 88.3288 2356.84 88.3288H2347.45ZM2345.43 72.8911C2346.17 70.609 2346.44 68.1926 2346.44 63.6956C2346.44 59.1985 2346.1 56.715 2345.43 54.5001C2344.09 50.4057 2340.94 48.1907 2336.24 48.1907C2331.54 48.1907 2328.45 50.4728 2327.11 54.5001C2326.37 56.7821 2326.1 59.1985 2326.1 63.6956C2326.1 68.1926 2326.44 70.6761 2327.11 72.8911C2328.45 76.9854 2331.6 79.2004 2336.24 79.2004C2340.87 79.2004 2344.09 76.9183 2345.43 72.8911Z"
        fill="#00BEFA"
      />
      <path
        d="M2377.24 38.6594V32.887L2372.27 35.9074C2371.94 36.1088 2371.47 35.9746 2371.27 35.639L2369.79 32.887C2369.59 32.5514 2369.72 32.0816 2370.06 31.8802L2375.09 29.0611L2370.06 26.2421C2369.72 26.0407 2369.59 25.5709 2369.79 25.2353L2371.27 22.4833C2371.47 22.1477 2371.94 22.0135 2372.27 22.2149L2377.31 25.2353V19.4629C2377.24 19.0602 2377.51 18.7246 2377.98 18.7246H2381.07C2381.47 18.7246 2381.81 18.9931 2381.81 19.4629V25.2353L2386.77 22.2149C2387.11 22.0135 2387.58 22.1477 2387.78 22.4833L2389.26 25.2353C2389.46 25.5709 2389.32 26.0407 2388.99 26.2421L2383.95 29.0611L2388.99 31.8802C2389.32 32.0816 2389.46 32.5514 2389.26 32.887L2387.78 35.639C2387.58 35.9746 2387.11 36.1088 2386.77 35.9074L2381.74 32.887V38.6594C2381.81 39.0621 2381.54 39.3977 2381.07 39.3977H2377.98C2377.58 39.3977 2377.24 39.1292 2377.24 38.6594Z"
        fill="#00BEFA"
      />
      <path
        d="M1269.39 87.5255L1272.48 69.6715C1272.61 68.8661 1272.08 68.3291 1271.27 68.3291H1253.55C1252.74 68.3291 1252.21 68.8661 1252.07 69.6715L1248.99 87.5255C1248.85 88.331 1249.39 88.868 1250.19 88.868H1267.91C1268.72 88.868 1269.26 88.331 1269.39 87.5255Z"
        fill="#00BEFA"
      />
      <path
        d="M1322.56 87.5905L1337.73 2.21327C1337.87 1.40782 1337.33 0.937988 1336.59 0.937988H1319.14C1318.33 0.937988 1317.6 1.20646 1316.79 1.74343L1299.34 12.8854C1298.67 13.4224 1298.46 13.8922 1298.33 14.6977L1295.24 32.9544C1294.97 33.9612 1295.78 34.2297 1296.52 33.7599L1312.7 23.8261H1312.96L1301.55 87.7247C1301.42 88.5302 1301.96 89 1302.69 89H1321.22C1322.03 89 1322.5 88.463 1322.63 87.7247L1322.56 87.5905Z"
        fill="#00BEFA"
      />
      <path
        d="M1169.99 1.13934C1169.99 1.13934 1169.65 0.937988 1169.38 0.937988H1151.86C1151.13 0.937988 1150.92 1.60919 1150.86 1.87767L1141.66 53.7618H1141.33L1123.54 1.9448C1123.27 1.2736 1122.8 0.937988 1121.99 0.937988H1103.4C1102.66 0.937988 1102.46 1.60919 1102.39 1.87767L1087.09 88.1945C1087.09 88.1945 1087.09 88.5302 1087.22 88.7315C1087.36 88.8658 1087.56 88.9329 1087.83 88.9329H1105.35C1105.75 88.9329 1106.08 88.5973 1106.22 87.9932L1115.41 36.1091H1115.88L1133.67 87.9261C1133.87 88.5973 1134.28 88.9329 1135.08 88.9329H1153.81C1154.55 88.9329 1154.75 88.3288 1154.82 87.9932L1170.12 1.67632C1170.12 1.67632 1170.12 1.27358 1169.99 1.13934Z"
        fill="#00BEFA"
      />
      <path
        d="M1216.51 0C1201.2 0 1189.12 6.10796 1182.2 20.2704C1177.51 29.8015 1173.68 54.0319 1173.68 62.422C1173.68 80.4103 1185.16 89.6729 1202.48 89.6729C1217.65 89.6729 1229.73 83.5649 1236.78 69.4025C1241.48 59.8714 1245.3 35.641 1245.3 27.2509C1245.3 9.26263 1233.82 0 1216.51 0ZM1206.3 79.8733H1206.17V53.8306H1190.73L1212.55 9.93382H1212.68V35.9766H1228.19L1206.3 79.8733Z"
        fill="#00BEFA"
      />
      <path d="M501 1H495V89H501V1Z" fill="#00BEFA" />
      <path
        d="M245.98 56.2612L266.672 89.2124L285.739 89.2456L256.36 42.5039L245.98 56.2612Z"
        fill="#00BEFA"
      />
      <path
        d="M421.959 52.9463C432.172 45.1229 436.649 37.0011 435.919 26.5256C434.858 11.2766 422.523 1.0332 408.463 1.0332H368.107V89.2457H384.157V52.9463H403.091L425.772 89.2457H444.707L421.959 52.9463ZM420.367 26.5588C420.367 33.3214 415.626 37.2994 408.364 37.2994H384.157V16.6138H408.364C417.516 16.6138 420.367 22.9123 420.367 26.5588Z"
        fill="#00BEFA"
      />
      <path
        d="M279.937 1H259.577L217.629 56.7585V1H201.514V81.4222C201.514 84.3725 203.238 88.0522 207.847 89.0799C209.671 89.5108 213.352 89.0799 215.54 86.1626C215.806 85.798 279.97 1 279.97 1"
        fill="#00BEFA"
      />
      <path
        d="M347.285 37.2993H315.617V52.913H347.285V37.2993Z"
        fill="#00BEFA"
      />
      <path
        d="M355.441 1H292.57V81.389C292.57 85.7317 296.085 89.2456 300.429 89.2456H355.441V73.6651H308.653V16.6469H355.441V1.03315V1Z"
        fill="#00BEFA"
      />
      <path
        d="M169.282 1V56.7585L127.335 1H106.842V89.1793H122.924V22.1166C141.958 47.2444 171.172 85.9306 171.371 86.1627C173.559 89.0467 177.24 89.5108 179.064 89.0799C183.673 88.0191 185.398 84.3725 185.398 81.4222V1H169.282Z"
        fill="#00BEFA"
      />
      <path
        d="M41.2509 5.77362L0 89.2456H17.9727L49.0435 26.5919L80.0811 89.2456H97.888L56.9355 6.03882C55.4102 2.95586 52.2931 1 48.8445 1C45.5948 1 42.6436 2.85641 41.2177 5.74047"
        fill="#00BEFA"
      />
      <path
        d="M63.6342 76.7478H53.023C53.023 76.7478 52.8572 76.6484 52.8572 76.5489L54.9131 53.8742C54.9131 53.7085 54.7142 53.609 54.6147 53.7416L31.7344 83.6762C31.7344 83.6762 31.7344 83.9414 31.867 83.9414H42.5113C42.5113 83.9414 42.6771 84.0408 42.6771 84.1403L40.6212 106.815C40.6212 106.981 40.8202 107.08 40.9197 106.948L63.8 77.013C63.8 77.013 63.8 76.7478 63.6673 76.7478"
        fill="#00BEFA"
      />
    </svg>
  )
}

export default Ankerslogen
